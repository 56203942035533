export const eventsData = [
  [
    {
      name: 'Bing-Bang Finchball',
      pos: '0% 50%',
      image: 'Bing-Bang Finchball.png',
      start: '2024-08-06 10:00:00',
      end: '2024-08-19 03:59:59',
      color: '#c2e62b',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/31777295',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Mutual Security Enhancing Simulation',
      pos: '0% 0%',
      image: 'Mutual Security Enhancing Simulation.png',
      start: '2024-06-07 10:00:00',
      end: '2024-06-17 03:59:59',
      color: '#f9e87e',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/29523218',
      showOnHome: true,
    },
    {
      name: 'Record of Refelective Writing',
      pos: '0% 40%',
      image: 'Record of Refelective Writing .png',
      start: '2024-06-18 10:00:00',
      end: '2024-06-24 03:59:59',
      color: '#ffa066',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/29435984',
      showOnHome: true,
    },
    {
      name: 'Endless Forms Most Martial',
      pos: '0% 0%',
      image: 'Endless Forms Most Martial.png',
      start: '2024-06-25 10:00:00',
      end: '2024-07-01 03:59:59',
      color: '#f0e6ca',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/30192615',
      showOnHome: true,
    },
    {
      name: 'Spino Doubleblaster',
      pos: '0% 40%',
      image: 'Spino Doubleblaster.png',
      start: '2024-07-02 10:00:00',
      end: '2024-07-08 03:59:59',
      color: '#f0e6ca',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/30072005',
      showOnHome: true,
    },
    {
      name: 'Ley Line Overflow',
      pos: '0% 20%',
      image: 'leyline_overflow.jpg',
      start: '2024-07-08 10:00:00',
      end: '2024-07-15 03:59:59',
      color: '#5baced',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/30072005',
      showOnHome: true,
    },
    {
      name: 'Outside the Canvas, Inside the Lens: Dew-Kissed Chapter',
      pos: '0% 20%',
      image: 'Outside the Canvas, Inside the Lens Dew-Kissed Chapter.png',
      start: '2024-07-29 10:00:00',
      end: '2024-08-08 03:59:59',
      color: '#b1d1e6',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/31507365',
      showOnHome: true,
    },
    {
      name: 'Energy Amplifier: Lemma',
      pos: '0% 50%',
      image: 'energy_amplifier_fruition.jpg',
      start: '2024-08-12 10:00:00',
      end: '2024-08-26 03:59:59',
      color: '#41b6c8',
      zoom: '200%',
      url: 'https://www.hoyolab.com/article/31677110',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Summertide Scales and Tales',
      pos: '0% 0%',
      image: 'Summertide Scales and Tales.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-27 03:59:59',
      color: '#64b1e8',
      zoom: '100%',
      url: 'https://www.hoyolab.com/article/31070415',
      showOnHome: true,
      timezoneDependent: true,
    },
  ],
  [
    {
      name: 'Illuminating Lightning - Clorinde Banner',
      pos: '40% 20%',
      zoom: '200%',
      image: 'Illuminating Lightning 1.png',
      start: '2024-06-05 06:00:00',
      end: '2024-06-25 17:59:00',
      color: '#6968ff',
      url: 'https://www.hoyolab.com/article/29435758',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Romaritime Meluserenity - Sigewinne Banner',
      pos: '40% 20%',
      zoom: '200%',
      image: 'Romaritime Meluserenity 1.png',
      start: '2024-06-25 18:00:00',
      end: '2024-07-16 14:59:00',
      color: '#fadbf8',
      url: 'https://www.hoyolab.com/article/30071782',
      showOnHome: true,
    },
    {
      name: 'In the Name of the Rosula - Navia Banner',
      pos: '40% 20%',
      zoom: '200%',
      image: 'In the Name of the Rosula 2.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-06 17:59:00',
      color: '#f6e195',
      url: 'https://www.hoyolab.com/article/31070419',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Ambrosial Essence - Emilie Banner',
      pos: '20% 20%',
      zoom: '200%',
      image: 'Ambrosial Essence 1.png',
      start: '2024-08-06 18:00:00',
      end: '2024-08-27 14:59:00',
      color: '#cde8a4',
      url: 'https://www.hoyolab.com/article/31676908',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Caution in Confidence - Alhaitham Banner',
      pos: '20% 10%',
      zoom: '200%',
      image: 'Caution in Confidence 3.png',
      start: '2024-06-05 06:00:00',
      end: '2024-06-25 17:59:00',
      color: '#5bcbd1',
      url: 'https://www.hoyolab.com/article/29435758',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Chanson of Many Waters - Furina Banner',
      pos: '40% 15%',
      zoom: '200%',
      image: 'Chanson of Many Waters 2.png',
      start: '2024-06-25 18:00:00',
      end: '2024-07-16 14:59:00',
      color: '#95d6fb',
      url: 'https://www.hoyolab.com/article/30071782',
      showOnHome: true,
    },
    {
      name: 'Twirling Lotus - Nilou Banner',
      pos: '40% 20%',
      zoom: '200%',
      image: 'Twirling Lotus 3.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-06 17:59:00',
      color: '#b2e0f0',
      url: 'https://www.hoyolab.com/article/31070419',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Discerner of Enigmas - Yelan Banner',
      pos: '20% 20%',
      zoom: '200%',
      image: 'Discerner of Enigmas 4.png',
      start: '2024-08-06 18:00:00',
      end: '2024-08-27 14:59:00',
      color: '#7de6fd',
      url: 'https://www.hoyolab.com/article/31676908',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 30%',
      zoom: '200%',
      image: 'Epitome Invocation 65.png',
      start: '2024-06-05 06:00:00',
      end: '2024-06-25 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/29435758',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 30%',
      zoom: '200%',
      image: 'Epitome Invocation 66.png',
      start: '2024-06-25 18:00:00',
      end: '2024-07-16 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/30071782',
      showOnHome: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '40% 30%',
      zoom: '200%',
      image: 'Epitome Invocation 67.png',
      start: '2024-07-17 06:00:00',
      end: '2024-08-06 17:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/31070419',
      showOnHome: true,
      timezoneDependent: true,
    },
    {
      name: 'Epitome Invocation - Weapon Banner',
      pos: '30% 20%',
      zoom: '200%',
      image: 'Epitome Invocation 68.png',
      start: '2024-08-06 18:00:00',
      end: '2024-08-27 14:59:00',
      color: '#FFAA4B',
      url: 'https://www.hoyolab.com/article/31676908',
      showOnHome: true,
    },
  ],
  [
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-06-01 04:00:00',
      end: '2024-06-16 04:00:00',
      color: '#4299E1',
      description:
        "When a character's HP value increases or decreases, that character gains a 16% increased All Elemental and Physical DMG Bonus for 8s. This effect can stack up to 3 times, and the duration of each stack is counted independently.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-06-16 04:00:00',
      end: '2024-07-16 04:00:00',
      color: '#4299E1',
      description:
        "For 10s after the active character uses their Elemental Skill, DMG dealt by their Normal Attacks is increased by 20%, and a tally will be kept when this character's Normal Attacks hit opponents. The tally can increase by 1 up to once every 0.1s, and once it reaches 3, a shockwave is unleashed at the opponent's location that deals True DMG to nearby opponents, and the tally will be reset. Up to 1 shockwave can be unleashed this way every 1.5s, and the effect and tally are cleared when the character leaves the field.",
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-07-16 04:00:00',
      end: '2024-08-16 04:00:00',
      color: '#4299E1',
      description:
        'After this character receives healing, their Elemental Skill DMG is increased by 20%. Lasts for 8s, up to 3 stacks. Each stack duration is calculated independently',
    },
    {
      name: 'Spiral Abyss',
      image: 'spiral_abyss.jpg',
      pos: '50% 20%',
      start: '2024-08-16 04:00:00',
      end: '2024-09-16 04:00:00',
      color: '#4299E1',
      description:
        'When characters attack Burning opponents, their CRIT Rate is increased by 15% and their CRIT DMG is increased by 30%.',
    },
  ],
  [
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-07-01 10:00:00',
      end: '2024-08-01 04:00:00',
      color: '#777df3',
      description: 'Required Elemental Types: Pyro, Electro, and Anemo. Max HP, ATK, and DEF are increased by 20%.',
    },
    {
      name: 'Imaginarium Theater',
      image: 'Imaginarium Theater tmp.png',
      pos: '0% 70%',
      start: '2024-08-01 04:00:00',
      end: '2024-08-31 04:00:00',
      color: '#777df3',
      description:
        'Required Elemental Types: Hydro, Cryo, and Anemo. All characters in the party gain a 75% Dendro DMG Bonus',
    },
  ],
  [
    {
      name: 'Battle Pass - Simulnation',
      pos: '0% 45%',
      image: 'Simulnation.png',
      start: '2024-06-05 06:00:00',
      end: '2024-07-15 03:59:59',
      color: '#ffba8d',
      url: 'https://www.hoyolab.com/article/29435984',
      timezoneDependent: true,
    },
    {
      name: 'Battle Pass - Fanciful Fabrication',
      pos: '0% 45%',
      image: 'Fanciful Fabrication.jpg',
      start: '2024-07-17 06:00:00',
      end: '2024-08-26 03:59:59',
      color: '#8b9fd1',
      url: 'https://www.hoyolab.com/article/31070697',
      timezoneDependent: true,
    },
  ],
];
